<template>
  <main class="other-auth">
    <div class="text">
      <i class="fa-solid fa-paper-plane fa-4x"></i>
      <span class="title">Please verify your email address</span>
      <span class="subtitle"
        > We sent you a verification email to <b>{{ email }}</b>. Please verify your email to finish your registration.
        </span
      >
    </div>
    <div class="continue">
      <router-link to="/">Continue to app</router-link>
    </div>
  </main>
</template>

<script>
export default {
  computed: {
    email() {
      return this.$route.query.email;
    },
  }
};
</script>

<style>
</style>